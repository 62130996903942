.Field {
    max-width: 700px;
    margin: 0 auto;
    border: 1px solid green;
}

.Row {
    display: flex;
    border-bottom: 1px solid green;
}

.Row:last-child {
    border-bottom: 0;
}

.ResetGameButton {
    font-size: 20px;
    padding: .3rem .7rem .5rem .5rem;
    margin: 0;
    border: 0;
    background: grey;
    position: absolute;
    top: 0;
    right: 0;
}