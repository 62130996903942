body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.Cell {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
    border-right: 1px solid green;
    padding: .5rem;
    font-family: 'Fira Sans Condensed';
    position: relative;
}

.Cell:last-child {
    border-right: 0;
}

.Cell:after {
    content: "";
    display: block;
    padding-bottom: 120%;
}

.IsSelected {
    background-color: lightgreen;
    color: darkgreen;
    font-weight: 700;
}

.CellDeselect {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(215, 0, 0, .7);
    display: block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-weight: 400;
}

@media (min-width: 500px) {
    .Cell {
        font-size: 1rem;
    }
}

@media (min-width: 700px) {
    .Cell {
        font-size: 1.2rem;
    }
}
.Field {
    max-width: 700px;
    margin: 0 auto;
    border: 1px solid green;
}

.Row {
    display: flex;
    border-bottom: 1px solid green;
}

.Row:last-child {
    border-bottom: 0;
}

.ResetGameButton {
    font-size: 20px;
    padding: .3rem .7rem .5rem .5rem;
    margin: 0;
    border: 0;
    background: grey;
    position: absolute;
    top: 0;
    right: 0;
}
